<script lang="ts" setup>
const props = withDefaults(
	defineProps<{
		number?: number;
		decimals?: number;
		useThousandSeparator?: boolean;
		notAnimate?: boolean;
	}>(),
	{ number: 0, decimals: 0, useThousandSeparator: false }
);

const animationFrameId = ref<number>();
const displayNumber = ref(props.number);

const animationDuration = computed(() => {
	const difference = Math.abs(props.number - displayNumber.value);
	return Math.min(2000, Math.max(500, difference));
});
const cancelAnimation = () => {
	if (animationFrameId.value) {
		cancelAnimationFrame(animationFrameId.value);
	}
};

const animateNumber = (startValue: number, endValue: number, duration: number) => {
	const startTime = performance.now();

	const animate = (currentTime: number) => {
		const elapsedTime = currentTime - startTime;
		const progress = Math.min(elapsedTime / duration, 1);

		displayNumber.value = parseFloat((startValue + progress * (endValue - startValue)).toFixed(props.decimals));

		if (elapsedTime < duration) {
			animationFrameId.value = requestAnimationFrame(animate);
		} else {
			displayNumber.value = parseFloat(endValue.toFixed(props.decimals));
		}
	};

	animationFrameId.value = requestAnimationFrame(animate);
};

watch(
	() => props.number,
	(newVal) => {
		if (props.notAnimate) {
			displayNumber.value = newVal;
			return;
		}

		cancelAnimation();
		animateNumber(displayNumber.value, newVal, animationDuration.value);
	}
);

onBeforeUnmount(cancelAnimation);

const formattedNumber = computed(() => {
	if (props.useThousandSeparator) {
		return displayNumber.value.toLocaleString("en-US", {
			minimumFractionDigits: props.decimals,
			maximumFractionDigits: props.decimals
		});
	}

	return numberFormat(displayNumber.value, { minimumFractionDigits: props.decimals });
});
</script>

<template>
	<span>{{ formattedNumber }}</span>
</template>
